import { Link } from "gatsby"
import React, { useState } from "react"
import { useNav } from "../../../hooks/useNav"
import "./Navigation.css"

export default function Navigation() {
  const data = useNav()
  const [menu, setMenu] = useState(false)

  return (
    <div className="header__mega--menu">
      <nav className={`${menu ? "open" : ""}`}>
        <ul className="vt-nav-menu flex flex-row">
          {data.wpMenu.menuItems.nodes.map((item, i) => {
            return (
              <li key={i} className="mr-[40px]">
                <Link
                  to={item.uri}
                  onClick={() => {
                    setMenu(false)
                  }}
                  activeClassName={"text-primary"}
                >
                  {item.label}
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
      <div className="menu__mobile--controls">
        <div
          className={`menu__toggle ${menu ? "active" : ""}`}
          onClick={() => {
            setMenu(!menu)
          }}
        >
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  )
}
