import { Link } from "gatsby"
import * as React from "react"
import Container from "../../atoms/Container"
import Navigation from "../../atoms/Navigation"
import { useHeader } from "../../../hooks/useHeader"
import PrimaryButton from "../../atoms/Buttons/PrimaryButton"
import "./Header.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import callIcon from "../../../images/call.svg"

export default function Header({ scrolled }) {
  const data = useHeader()
  const image = getImage(
    data.wp.acfOptionsHeader.header.logo.localFile.childImageSharp
      .gatsbyImageData
  )
  console.log(data)

  return (
    <header
      className={`vt-header py-[40px] ${scrolled ? `vt-header-scrolled` : ""}`}
    >
      <Container>
        <div className="vt-header-inner">
          <div className="vt-header-inner-left">
            <Link to="/">
              <GatsbyImage
                className="vt-header-logo"
                image={image}
                objectFit="contain"
                imgStyle={{ objectFit: "contain" }}
              />
            </Link>
            <Navigation />
          </div>
          <div className="vt-header-inner-buttons">
            <PrimaryButton
              icon={callIcon}
              iconPosition={"before"}
              label={
                data.wp.acfOptionsHeader.header.telefoonButton.telefoonnummer
              }
              target={data.wp.acfOptionsHeader.header.telefoonButton.link}
            />
            <PrimaryButton
              label={data.wp.acfOptionsHeader.header.callToAction.label}
              color={"red"}
              target={data.wp.acfOptionsHeader.header.callToAction.link}
            />
          </div>
        </div>
      </Container>
    </header>
  )
}
