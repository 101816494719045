import { Link } from "gatsby"
import React from "react"
import { useFooter } from "../../../hooks/useFooter"
import Container from "../../atoms/Container"
import "./footer.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Footer() {
  const data = useFooter()
  const image = getImage(
    data.wp.acfOptionsFooter.footer.logo.localFile.childImageSharp
      .gatsbyImageData
  )

  const fb = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M15.3447 13.25L15.8632 9.63047H12.621V7.28164C12.621 6.29141 13.0739 5.32617 14.526 5.32617H16V2.24453C16 2.24453 14.6624 2 13.3835 2C10.7134 2 8.96806 3.73359 8.96806 6.87187V9.63047H6V13.25H8.96806V22H12.621V13.25H15.3447Z"
        className="group-hover:fill-primary transition duration-300"
        fill="#FFFFFF"
      />
    </svg>
  )

  const li = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M7.02911 21H3.29732V8.98232H7.02911V21ZM5.16121 7.343C3.9679 7.343 3 6.35458 3 5.16125C3 4.58805 3.2277 4.03833 3.633 3.63302C4.03831 3.2277 4.58802 3 5.16121 3C5.73439 3 6.2841 3.2277 6.68941 3.63302C7.09471 4.03833 7.32241 4.58805 7.32241 5.16125C7.32241 6.35458 6.35411 7.343 5.16121 7.343ZM20.996 21H17.2722V15.1499C17.2722 13.7556 17.2441 11.9677 15.332 11.9677C13.3918 11.9677 13.0945 13.4824 13.0945 15.0494V21H9.3667V8.98232H12.9458V10.6216H12.998C13.4963 9.67743 14.7133 8.68097 16.5289 8.68097C20.3057 8.68097 21 11.1681 21 14.3985V21H20.996Z"
        className="group-hover:fill-primary transition duration-300"
        fill="#FFFFFF"
      />
    </svg>
  )
  const year = new Date().getFullYear()

  return (
    <footer className="vt-footer-container py-[100px]">
      <Container>
        <div>
          <Link to="/">
            <GatsbyImage
              className="vt-footer-logo"
              image={image}
              objectFit="contain"
              imgStyle={{ objectFit: "contain" }}
            />
          </Link>
        </div>
        <div className="vt-footer-container-nav-menu">
          <div className="vt-footer-column-1">
            <h4>Website</h4>
            <div
              className="vt-footer-text"
              dangerouslySetInnerHTML={{
                __html: data.wp.acfOptionsFooter.footer.websiteMenu,
              }}
            ></div>
          </div>
          <div className="vt-footer-column-2">
            <h4>Diensten</h4>
            <div
              className="vt-footer-text"
              dangerouslySetInnerHTML={{
                __html: data.wp.acfOptionsFooter.footer.dienstenMenu,
              }}
            ></div>
          </div>
          <div className="vt-footer-column-3">
            <h4>Legal</h4>
            <div
              className="vt-footer-text"
              dangerouslySetInnerHTML={{
                __html: data.wp.acfOptionsFooter.footer.legalMenu,
              }}
            ></div>
          </div>
          <div className="vt-footer-column-4">
            <h4>Adres</h4>
            <div
              className="vt-footer-text"
              dangerouslySetInnerHTML={{
                __html: data.wp.acfOptionsFooter.footer.contact.contactgegevens,
              }}
            ></div>
            <div className="vt-footer-social">
              <a
                href={
                  data.wp.acfOptionsFooter.footer.contact.socials.facebookLink
                }
                target="_blank"
              >
                {fb}
              </a>

              <a
                href={
                  data.wp.acfOptionsFooter.footer.contact.socials.linkedinLink
                }
                target="_blank"
              >
                {li}
              </a>
            </div>
          </div>
        </div>
        <div className="vt-footer-container-2">
          <div className="vt-footer-copyright">
            &copy; {year}{" "}
            <div
              className="vt-footer-text"
              dangerouslySetInnerHTML={{
                __html: data.wp.acfOptionsFooter.footer.copyright,
              }}
            ></div>
          </div>
          <div
            className="vt-footer-text"
            dangerouslySetInnerHTML={{
              __html: data.wp.acfOptionsFooter.footer.websiteDoor,
            }}
          ></div>
        </div>
      </Container>
    </footer>
  )
}
