import { useStaticQuery, graphql } from "gatsby"

export const useHeader = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      wp {
        acfOptionsHeader {
          header {
            callToAction {
              label
              link
            }
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            telefoonButton {
              link
              telefoonnummer
            }
          }
        }
      }
    }
  `)

  return data
}
