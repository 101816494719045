import React, { useEffect, useState } from "react"
import Footer from "../../molecules/Footer"
import Header from "../../molecules/Header"
import GlobalStyles from "../GlobalStyles"
import { AnimatePresence } from "framer-motion"

const Layout = ({ children }) => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 50) {
        setScrolled(true)
      } else if (window.pageYOffset == 0) {
        setScrolled(false)
      }
    }
  }, [])

  return (
    <>
      <GlobalStyles />
      <Header scrolled={scrolled} />
      <main>
        <AnimatePresence mode="wait">{children}</AnimatePresence>
      </main>
      <Footer />
    </>
  )
}

export default Layout
