import { graphql, useStaticQuery } from "gatsby"
import { createGlobalStyle } from "styled-components"
import React from "react"
import Helmet from "react-helmet"

export default function GlobalStyles() {
  const data = useStaticQuery(graphql`
    query settingsQuery {
      wp {
        acfOptionsTypografie {
          typografie {
            headerfonts {
              navigatiefont
            }
            standaardfonts {
              h1font
              h2font
              h3font
              textfont
            }
          }
        }
        acfOptionsKleuren {
          kleuren {
            header {
              background
              textcolor
            }
            footer {
              background
              textcolor
            }
            textcolors {
              h1color
              h2color
              h3color
              textcolor
            }
            kleurpalet {
              accentcolor
              bgcolor
              primarycolor
              secondarycolor
              lightcolor
            }
          }
        }
      }
    }
  `)

  // Palet
  const primary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.primarycolor
  const secondary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.secondarycolor
  const accent = data.wp.acfOptionsKleuren.kleuren.kleurpalet.accentcolor
  const text = data.wp.acfOptionsKleuren.kleuren.textcolors.textcolor
  const background = data.wp.acfOptionsKleuren.kleuren.kleurpalet.bgcolor
  const light = data.wp.acfOptionsKleuren.kleuren.kleurpalet.lightcolor

  // Header
  const headerBackground = data.wp.acfOptionsKleuren.kleuren.header.background
  const headerText = data.wp.acfOptionsKleuren.kleuren.header.textcolor

  // Footer
  const footerBackground = data.wp.acfOptionsKleuren.kleuren.footer.background
  const footerText = data.wp.acfOptionsKleuren.kleuren.footer.textcolor

  // Headings
  const h1 = data.wp.acfOptionsKleuren.kleuren.textcolors.h1color
  const h2 = data.wp.acfOptionsKleuren.kleuren.textcolors.h2color
  const h3 = data.wp.acfOptionsKleuren.kleuren.textcolors.h3color

  // Fonts
  const h1font = data.wp.acfOptionsTypografie.typografie.standaardfonts.h1font
  const h2font = data.wp.acfOptionsTypografie.typografie.standaardfonts.h2font
  const h3font = data.wp.acfOptionsTypografie.typografie.standaardfonts.h3font
  const textfont =
    data.wp.acfOptionsTypografie.typografie.standaardfonts.textfont

  // Navigatie
  const navigationFont =
    data.wp.acfOptionsTypografie.typografie.headerfonts.navigatiefont

  const GlobalStyle = createGlobalStyle`

    :root {
      --primary-color: ${primary};
      --secondary-color: ${secondary};
      --accent-color: ${accent};
      --text-color: ${text};
      --bg-color: ${background};
      --light-color: ${light};
      --text-font: 'roc-grotesk';
      --h1-font: 'roc-grotesk';
      --h2-font: 'roc-grotesk';
      --h3-font: 'roc-grotesk';
      --nav-font: 'roc-grotesk';
    }
    body{
      font-family: var(--text-font);
    }
    h1{
      color: ${h1};
      font-family: var(--h1-font), sans-serif;
      font-weight: 900;
      font-style: normal;
    }
    h2{
      color: ${h2};
      font-family: var(--h2-font), sans-serif;
      font-weight: 700;
      font-style: normal;
    }
    h3{
      color: ${h3};
      font-family: var(--h3-font), sans-serif;
      font-weight: 700;
      font-style: normal;
    }
    h4{
      color: ${h3};
      font-family: var(--h4-font), sans-serif;
      font-weight: 700;
      font-style: normal;
    }
    .headerClass{
      color: ${headerText};
    }
    .footerClass{
      background-color: ${footerBackground};
      color: ${footerText};
    }
    .footerClass h1,  .footerClass h2,  .footerClass h3,  .footerClass h4{
      color: ${footerText};
    }
    .navigation{
      font-family: var(--nav-font);
    }
  `

  return (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link rel="stylesheet" href="https://use.typekit.net/era2xwy.css" />
        <link
          href={`https://fonts.googleapis.com/css2?family=${h1font}:wght@100;200;300;400;500;600;700;800;900&display=swap`}
          rel="stylesheet"
        />
        <link
          href={`https://fonts.googleapis.com/css2?family=${h2font}:wght@100;200;300;400;500;600;700;800;900&display=swap`}
          rel="stylesheet"
        />
        <link
          href={`https://fonts.googleapis.com/css2?family=${h3font}:wght@100;200;300;400;500;600;700;800;900&display=swap`}
          rel="stylesheet"
        />
        <link
          href={`https://fonts.googleapis.com/css2?family=${textfont}:wght@100;200;300;400;500;600;700;800;900&display=swap`}
          rel="stylesheet"
        />
        <link
          href={`https://fonts.googleapis.com/css2?family=${navigationFont}:wght@100;200;300;400;500;600;700;800;900&display=swap`}
          rel="stylesheet"
        />
      </Helmet>
    </>
  )
}
