import { useStaticQuery, graphql } from "gatsby"

export const useFooter = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      wp {
        acfOptionsFooter {
          footer {
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            websiteDoor
            copyright
            contact {
              contactgegevens
              socials {
                facebookLink
                linkedinLink
              }
            }
            dienstenMenu
            websiteMenu
            legalMenu
          }
        }
      }
    }
  `)

  return data
}
