import { Link } from "gatsby"
import React from "react"
import "./PrimaryButton.css"

export default function PrimaryButton({
  target,
  label,
  color,
  icon,
  iconPosition,
}) {
  const iconURL = icon?.localFile ? icon?.localFile.publicURL : icon

  return (
    <Link to={target}>
      <div
        className={`${
          color == "red" ? "vt-primary-button__red" : "bg-white text-text"
        } vt-primary-button`}
      >
        {icon && iconPosition == "before" ? <img src={iconURL} /> : null}
        <span>{label}</span>
        {icon && iconPosition == "after" ? <img src={iconURL} /> : null}
      </div>
    </Link>
  )
}
